@import '../../../mixins';

/* ----- profile ----- */
.ant-dropdown-menu-item:hover, {
  color: $pdblue;
  background-color: $porange !important;
}
.ant-dropdown-menu {
  border-radius: 1rem;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-item-active { background-color: $primary-color !important;
}
/* --- menu divider color --- */
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
   background: $secondary-color !important;


  .ant-menu-item {
    color: $white;
  }
}


/* --- menu divider color ? --- */

.ant-menu {
  .navigation {
    .ant-menu,
    .ant-menu-submenu,
    .ant-menu-item {
      color: $white;
      text-transform: uppercase;
      border-radius: .8rem;
      .ant-menu-sub {
        background: $secondary-color;
        color: $white;
      }
    }

    .ant-menu-dark, // focus
    .ant-menu-submenu-open {
      background: $primary-color;
      color: $white;
      border-bottom: 1px solid $secondary-color;
    }


    .ant-menu-dark.ant-menu-inline .ant-menu-dark  {
      background-color: $prospera-secondary;
    }


    .ant-menu-item:hover,
    .ant-menu-submenu:hover, {
      background-color: $primary-color !important;
    }

    .ant-menu-title-content {
      font-size: 1.1rem;
    }

    .ant-menu-title-content .active {
      color: $white;
      background-color: $primary-color !important;
    }

    .ant-menu .ant-menu-dark,
    .ant-menu-dark,
    .ant-menu-sub,
    .ant-menu-item-only-child,
    .ant-menu-item-selected,
    .ant-menu-root,
    .ant-menu-inline {
      background: $primary-color;
    }

  }
}


/*
.ant-menu {
  &-item {
    &-group-title {
      color: $white;
    }
    &-disabled {
      color: $gray-2 !important;
    }
  }
  .ant-menu,
  .ant-menu .ant-menu-sub {
    background: $secondary-color;
    // @include nav-gradient;
    .ant-menu-item {
      color: $white;
    }
  }
}
*/
.ant-menu-item {
    color: $white;
    background: $secondary-color;
      li {
        color: $white;
        background-color: $secondary;
    &:hover {
      .icon {
        color: $white;
        background-color: $primary-color;
      }
    }
  }
}
// This is title on menu and when it is open
    .ant-menu-submenu:hover,
      .ant-menu-submenu-selected,
      .ant-menu-submenu-open,
        .ant-menu-submenu-active, {
        background-color: $primary-color !important;
        .icon {
          color: $tertiary-color !important;
        }
      }

// .ant-menu-item-active,
// .ant-menu-submenu-active,
// .ant-menu-item-open,




li .ant-menu-item,
.ant-menu-item-only-child,
.ant-menu-item-selected, {
  color: $white;
  background: $primary-color;
  border-radius: .5rem;
}


// background of menus
// removed global impacted font
// this works
/* all the way to line 41 */
.ant-menu,
.ant-menu .ant-menu-submenu, .ant-menu-item {
  color: white;
  text-transform: uppercase;
  background: $secondary; // IT IS THIS ONE @TODO  for background

  .ant-menu-sub{
  background: $secondary-color;
  color: $white;
}





// THIS IS SELECTING AN ITEM on the MENU
// trying to see what is going on was $prospera-secondary

  .ant-menu-item {
    color: white;
    background: $secondary;
  }
}





/* .ant-menu-dark */.ant-menu-inline.ant-menu-sub {
  background: $secondary-color;
}


.ant-menu-item-selected,
.ant-menu-submenu-selected {
  > a {
    color: $white;
    background: $primary-color;

    &:hover {
      .icon {
        color: $white;
        background-color: $primary-color;
      }
    }
  }
}
.ant-menu-item:after {
  border-right: 1rem solid $white;
 // color: $primary-color;
  right: auto;
  left: 0;
}


// 261    308
// 266
// 278

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: $white;
  background-color: $primary-color;
}


.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}




// removed dark before :not
/* .ant-menu-dark */
.ant-menu:not(.ant-menu-horizontal),
.ant-menu-item-selected,
.ant-menu-item {
  &-menu-item-selected {
    background-color: $primary-color !important;
    color: $white;
    border-radius: 1rem;
    a {
      color: $white !important; // didnt work
    }
    &-menu-item-active {
      font-weight: 700;
      background-color: $prospera-primary;
    }
  }
}



// this works for a selected item keep it.
.ant-menu-dark .ant-menu-item-selected a {
  background-color: $primary-color !important;
}

// .ant-dropdown-menu-item
// .ant-menu-item
// &-hover {
// color: $white
// background-color: $primary-color
// }
.ant-menu-item:hover {
  color: $white;
  background-color: fade($primary-color, .5%) !important;
  a
  {
    color: $white;
    background-color: $primary-color;
  }

}

// the above should do better
/*
.ant-menu-item:hover a {
  background-color: $primary-color !important;
}

 */
/*
.ant-menu .ant-menu-item .ant-menu-submenu .ant-menu-item:hover a {
  background-color: $primary-color !important;
}
*/
/*
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a {
  background-color: $primary-color !important;
}

.ant-menu .ant-menu-item:hover,
.ant-menu .ant-menu-submenu,
.ant-menu-dark .ant-menu-item-active
.ant-menu-item .ant-menu-item:hover {
  background-color: $primary-color !important;
}
*/
/*
.ant-menu-dark .ant-menu-item:hover .ant-menu-submenu-title:hover a {
  background-color: $danger !important;
}
*/
/*
.ant-menu-dark .ant-menu-item-active .ant-menu-item-only-child {
  background-color: $primary-color !important;
}

.ant-menu-dark .ant-menu-item .ant-menu-dark .ant-menu-submenu-title:hover li {
  background-color: $primary-color !important;
}
*/
















/* isn't working on all menu parents
.activeParent {
  font-weight: 800;
}
*/


////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
// todo needs to be fixed
/*
[data-kit-theme='s2n'] {
  .ant-menu {
    &-item {
      &-group-title {
        color: $dark-gray-2;
      }

      &-disabled {
        color: $dark-gray-3 !important;
      }
    }


 */
/*
    .ant-menu-dark .ant-menu-sub {
      color: $white;
      background: $secondary;
      //  background: $secondary-color-dark;

      .ant-menu-item {
        color: $white;
      }

      .ant-menu-item-divider {
        opacity: 1;
      }
    }
  }
}
*/

/*



  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop {
    color: white;
    background-color: $secondary;
  }
*/