@import '../../../mixins'; // our mixin

// "ant-layout-sider ant-layout-sider-dark ant-layout-sider-has-trigger menuLeftStyle_menu__1fDPW
// .ant-layout-sider {
//    position: fixed;
//    min-width: 0;
//    background: #001529;
//    transition: all 0.2s;
//}
// .ant-layout-sider-has-trigger {
//    padding-bottom: 48px;
//}

.ant-layout {
  min-height: 100vh !important;
  min-width: 0;
  background: transparent !important; // $pdgray;
 // display: none;
}
/* --- collapse -- */
.ant-layout-sider {
  background: $secondary-color;
  width: 16rem;
  .style {
    overflow: auto;
    height: 100vh !important;
    position: fixed;
    left: 0;
}

}

.ant-layout-header {
  position: fixed;
  // display: flex; this is for the profile to be on the far right
  padding: 0 !important;
  line-height: normal;
  height: auto;
  background: $white;
  color: $white;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  width: 100%;
}

.ant-layout-footer {
  padding: 0 !important;
  background: transparent; //$prospera-dark-gray;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: initial !important;
}

.ant-back-top {
  margin: 0;
  padding: 0;
  background-color: $secondary;
  font-size: 1rem;
  line-height: 1.5;
  position: fixed;
  right: 150px;
  bottom: 0;
  z-index: 10;
  width: 100%;
  height: auto;
  cursor: pointer;
  //  color: rgba(0, 0, 0, 0.85);
}
