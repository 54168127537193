// @import '../../../styles/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* PROGRESS */

// dark theme
[data-kit-theme='dark'] {
  .progress {
    background: $dark-gray-4;
  }
}
