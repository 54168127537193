@import '../../assets/mixins';

.dropdown {
  padding-right: 1rem;
  //padding: 0 1rem 0 2rem;
  position: relative;
  cursor: pointer;
  display: flex;
   &:hover {
    color: $plgray;
  }
}
// top bar avatar
.avatar {
  background-color: $prospera-light-gray;
  height: 2em;
  width: 2em;
  .ant-avatar,
  .ant-avatar-icon {
    font-size: 42px;
    line-height: 32px;
  }
}


.profileMenuAvatar {
  background-color: $prospera-light-gray;
  margin-top: 8% ;
 //            margin-left: 1%;
}
.topbarLink {
  &:hover,
  &:active,
  &:focus {
    color: $pdgray !important;
  }
}
.menuIcon {
  margin-right: 18%;
}
// not sure this should be here, but I believe it should 3.24.22
.settings {
  margin-right: 18%;
  color: $text;
  display: inline-block;
  .animate {
    &:hover,
    &:active,
    &:focus {
      color: $blue-light !important;
    }
  }
}

.signOut {
  margin-right: 18%;
  // font-size: 1.1rem;
  color: $text;
  display: inline-block;
   .animate {
    &:hover,
    &:active,
    &:focus {
      color: $blue-light !important;
    }
  }
}

 // style for profile menu
.customMenu {
  min-width: 18rem;
  right: 4%; // inset profile dropdown
  background-color: $secondary !important; // bg color for profile menu
  padding: .5rem !important;
  border-radius: .5rem;
  .ant-dropdown-menu-item-divider {
    margin: 1rem .2rem;

  }
}

.customMenuItem {
  background-color: $white;
  border-radius: .5rem;
  color: $text;
  margin: 3%; // margin around menu

}
.ant-row {
  align-items: center;
}