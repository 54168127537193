@import '../../../../assets/mixins';

.internalUserWrapper {
  .broker_free {
    background: $info;
    color: white;
  }
  .broker_premium {
    background: $light-pink;
    color: white;
  }
  .broker_clerical {
    background: $warning-more;
    color: white;
  }
  .fund_manager {
    background: $primary-dark;
    color: white;
  }
  .investor_free {
    background: $secondary;
    color: white;
  }
  .investor_fund {
    background: $black-lighten;
    color: white;
  }
  .investor_premium {
    background: $blue;
    color: white;
  }
  .investor_ria {
    background: $text-darken;
    color: white;
  }
  .leadTable {
    border-radius: rem(10);
    margin: -10px;
  }

  .addNewUserBtn {
    a {
      color: #fff !important;
    }
  }
}

.centered {
  text-align: center;
}
