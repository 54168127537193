@import '../../assets/mixins';

.breadcrumbs {
  padding: 10px 0 0 40px;

  a,
  span {
    color: $blue-2 !important;
  }

  .activeMenu {
    color: $primary;
  }
}

.arrow {
  display: inline-block;
  margin: 0 rem(10);
  &:after {
    display: inline-block;
    content: '·';
  }
}
// comment