// @import '../../../styles/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* LIST GROUP */

.list-group-item {
  border-color: $border;

  &.active {
    background: $purple;
  }
}
// sammy
// dark theme
[data-kit-theme='dark'] {
  .list-group-item {
    border-color: $dark-gray-4;
    background: $dark-gray-6;
    color: inherit;

    &.active {
      background: $purple !important;
    }

    &-action {
      color: $white !important;
      &:hover {
        background: $dark-gray-5;
      }
      &.disabled {
        opacity: 0.5;
        background: transparent;
      }
    }
  }
}
