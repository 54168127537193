@import '../../../mixins';


.ant-table {
  font-size: 1rem;
}
.customTable {
 // overflow: scroll;
  .ant-table-thead > tr > th {
    background: $prospera-primary;
   // border-bottom: .5px solid;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid $gray-2;

  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: transparent;
  }
  .ant-table-pagination.ant-pagination {
  //  background: $primary;
    padding: 5px 5px 0 0;
    margin: 0 0;
  }
}


.ant-table-small {
  .ant-table-tbody > tr > td {
    font-size: 1rem;
  }
  .ant-table-thead > tr > th {
    background-color: $primary-color;
    color: $white;
  }
}

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: .25rem .25rem;
  }
  .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: .25rem .25rem;
    background-color: $primary-color;
  }




// who us using tiny Table???/

.tinyTable {
  .ant-table-tbody > tr > td {
    font-size: 1rem;
  }
   .ant-table-thead > tr > th {
    // padding: 1px;
   background-color: $primary-color;
  }
}

/*
.userlist{
  clear: both;
  height: 182%;
  width:94%;
  padding: 510px;
  overflow: hidden;
  overflow-y: scroll;
}
.userlist::-webkit-scrollbar {
  width: 14px;
}
.userlist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgb(24, 144, 255);
  background: rgba(0,0,0,0.2);
}
.userlist::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0,0,0,0.1);
}

/*Define the height and width of the scroll bar and the height and width of the scroll bar respectively.
::-webkit-scrollbar
{
    width: 16px;
    height: 16px;
    background-color: #F5F5F5;
}

 /*Define the scroll bar track Inner shadow + fillet */
/*
::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}
*/
/*Define the slider Inner shadow + rounded */
/*
::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

*/