@import '../../assets/mixins';
// it is css to hide a profile. should be a page
.hidden {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  background: $gray-lighten-more;
  z-index: 1000;
  transform: translate3d(256px, 0, 0);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
// holds profile pop out
.settingsWrapper {
  position: fixed;
  top: 0;
  right: 0;
  background: transparent;
  z-index: 1000;
  // transform: translate3d(256px, 0, 0);
  transition: transform 0.3s ease-in-out, box-shadow 0.5s ease-in-out;
  width: 100%;
  height: 100vh;
}

.settingsOpened {
  position: fixed;
  right: 0;
  top: 6%;
  z-index: 10002;
  background-color: lighten($primary-color, 16%); opacity: .9;
          // lighten(#7d9c6e, 5%);

  // opacity: .8;
  border-radius: 1rem;
  width: 75vw;
  height: 85vh;
  // border: 1px;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 200px -20px rgba(57, 55, 73, 0.4);
}

.close {
  position: absolute;
  top: 5px;
  right: 10px;
  background: none;
  border: none;
  outline: none !important;
  cursor: pointer;
}

.container {
  padding: rem(22) rem(20);
  width: 99%;
  margin-top: -35px;
}

.exit {
  color: $white;
  padding-bottom: .5rem;
  margin-bottom: 1rem;
  // text-align: right;
  :global(.fa) {
    font-size: x-large;
    float: right;
    cursor: pointer;
    padding: .5rem .5rem;
    opacity: 0.6;
    &:hover {
      opacity: .9;
    }
  }
}

.topRow {
  margin-top: 2rem;
}

.description {
  margin-bottom: 2rem;
  color: $text-lighten;
}

.item {
  margin-bottom: 5rem;
}

.itemLabel {
  position: relative;
  top: 2px;
  margin-left: .5rem;
}
/* -- display profile information  card -- */
.centeredCard {
  background-color: #fff;
  border-radius: .6rem;
  padding: 2rem;
  max-height: 55vh;
  overflow: auto;
}

menu {
   background: $gray-lighten-more !important;
  :global(.ant-layout-sider-children) {
    position: fixed;
    height: 100%;
    max-width: 15rem;
    transition: width 0.2s;
  }
    // does this have to be global to work
  .ant-menu {
    background: #707070;
    border-radius: 1rem;
  }
}
/* ------- settings left menu component ------ */
.sideMenu {
  margin-top: 9rem; // why 9 and not 8
  border-radius: .8rem;
  color: $tertiary;
  background-color: $white;
  padding: 1% 2% 1% 0;
  .menu {
    border-radius: 1rem;
  }
}


.navigation {
  .ant-menu-item {
    &:hover {
      color: $secondary-color;
    }

    &-menu-item-selected {
      border-radius: 1rem;
      background-color: $primary-color !important;
      color: $white;
      a {
        color: $white;
        .icon {
          color: $white !important;
        }
        &-menu-item-active {
          font-weight: 700;
          background-color: $primary-color;
        }
      }
    }
  }
  // line on menu selected item
  .ant-menu-item:after {
    border-right: 1 solid $white !important;
    right: auto;
    left: 0;
  }
}

.tabIcon {
  i {
    margin-right: 1rem;
  }
}

.menuItem {
  width: 14rem;
}

.avatar {
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 1rem;
  .icon{
    // fontSize: '60px';
    height: 1.2em;
    width: 1.2em;
  }
}

// box under avatar
 username {
  text-align: center;
  color: #326380;
  font-size: 1.1rem;
  font-family: 'Permanent Marker', sans-serif;
  letter-spacing: 1px;
}

.userInfo {
  border-radius: .5rem;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  background-color: $white;
}
.bottomSpace {
  margin-bottom: 1rem;
}
.userAvatar {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: .5rem;
  .ant-upload-select-picture-card {
    background-color: transparent;
    border: none;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
.bottomSpace {
  margin-bottom: 1rem;
}


