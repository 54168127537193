@import '../../../mixins'; // our mixin
// @import '../../../styles/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* JUMBOTRON */

// dark theme
[data-kit-theme='dark'] {
  .jumbotron {
    background: $dark-gray-4;
  }
}
