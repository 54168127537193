@import '../../../assets/mixins';

.menu {
  .ant-menu-item-divider {
    height: 2.25rem !important;
    margin: .25rem 0 .25rem 0 !important;
    // margin-top: .4rem !important;
    width: 1px !important;
    float: left;
  }
  .title {
    padding-right: 1.5rem;
    background: $red;
  }
// background for menu? . this was ant-menu-dark
  .ant-menu-dark{
    &-menu-item-divider {
      background: $secondary-color; // don't see changes
    }
    .icon {
      color: $gray-darken; // no impact
    }

    .title {
      color: $gray-darken !important; // no impact
    }
  }
  .ant-menu-light {
    .icon {
      color: $text;
    }
    .title {
      color: $text;
    }
  }
}
/* ------ Menu ends here               ----- */
.logo {
  height: 3rem; // was 48px
  width: 9.4rem;
  background: $menuBg-darken;
  float: left;
}

.topMenuLogo {
  background: $menuBg-darken;
}

.logoContainer {
  height: 3rem; // was 48 px
  padding: 2px 10px 0 10px;
  img {
    height: 1.75rem;
  }
}

.icon {
  margin-top: 1rem;// rem(16);  no clue
  margin-right: 1rem; // rem(7);  no clue

  @media (max-width: $md-max-width) {
    display: none !important;
  }
}

.settings__menuTop .menu {
  display: inline-flex;
  flex-direction: row;

  .icon {
    order: -1;
  }
}

.settings__menuTop .ant-menu-item {
  display: inline-flex;
  flex-direction: row;

  .icon {
    order: -1;
  }
}

.settings__menuTop .ant-menu-light {
  border-bottom: none;
  box-shadow: inset 0 -1px 0 #e8e8e8;
}

.topMenuWrapper {
  display: flex;
}
// todo patrick
.topMenu {
  flex-grow: 1;
}

.profileMenu {
  background: $menuBg-darken;
  padding-top: 2px;

  .ant-dropdown-trigger {
    &:after {
      color: $gray-border;
    }
  }
}
