// original mixin file
@import "@fontsource/permanent-marker"; // Defaults to weight 400.
/* --- import fa-mixins --- */
@import '/fonts/font-awesome/scss/_mixins';
/* --- breakpoints --- */
@import '/abstracts/breakpoints';
/* --- colors --- */
// This needs to change to a subset of colors instead of all colors, but most of the colors are defined here.
// @import '/abstracts/_colors';


/* --- font families not working perfectly --- */
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src:
    url($file-name + '.woff') format('woff'),
    url($file-name + '.woff2') format('woff2'),
    url($file-name + '.ttf')  format('truetype'),
    url($file-name + '.svg##{$font-name}')  format('svg');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@include font-face(OpenSans, "./fonts/openSans/OpenSans", normal, normal);
@include font-face(Raleway, "./fonts/raleway/raleway", normal, normal);
@include font-face(PermanentMarker, "./fonts/permanentMarker/PermanentMarkerRegular", normal, normal);
@include font-face(ShreeDevangari, "./fonts/shreeDevanagari714/ShreeDevanagari714", normal, normal);

@font-face {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans');
  src: url('./fonts/openSans/OpenSans.woff2') format('woff2');
}
@font-face {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: local('raleyway');
  src: url('/fonts/raleway/raleway3.woff2') format('woff2');
}
@font-face {
  font-family: 'Permanent Marker', cursive;
  font-style: oblique;
  font-weight: 400;
  src: local('Permanent Marker');
  src: url('/fonts/permanentMarker/PermanentMarkerRegular.woff') format('woff');
  src: url('/fonts/permanentMarker/PermanentMarkerRegular.woff2') format('woff2');
  src: url('/fonts/permanentMarker/PermanentMarkerRegular.ttf') format('ttf');
}
@font-face {
  font-family: 'Shree Devanagari 714', sans-serif;
  font-style: normal;
  src: local('Shree Devanagari 714');
  src: url('/fonts/shreeDevanagari714/ShreeDevanagari714.woff2') format('woff2');
}
// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem;
}
//  @return #{$px}px; // to PXs
// }

$base-font-family: 'Open Sans', sans-serif;
$primary-font-family: 'Open Sans', sans-serif;
$primary-font: 'Open Sans', sans-serif;
$secondary-font-family: 'Raleway' serif;
$secondary-font: 'Raleway' serif;
$tertiary-font-family: 'Permanent Marker', 'Bradley Hand';
$tertiary-font: 'Permanent Marker', 'Comic Sans';
$quaternary-font-family: 'Shree Devanagari 714';
$quaternary-font: 'Shree Devanagari 714';
$icon-font: 'Font Awesome Pro 5';

$primary: #7d9c6e;
$secondary: #69825C;

// Font Size - no px needed
$base-font-size: 16 !default;
// bootstrap font
$font-size-base: 16px !default;
// Font Family
$base-font-family: 'Open Sans', sans-serif;
$selected-color: $primary;
$selection: $primary;


// Prospera Colors scheme
// orange
$prospera-orange: #FC8B20;
$porange: #FC8B20;
// light gray
$prospera-light-gray: #d3d3d3;
$plgray: #d3d3d3;
// gray
$prospera-gray: #949494;
$pgray: #949494;
// dark gray
$prospera-dark-gray: #545454;
$pdgray: #545454;
// prospera-light-blue
$prospera-light-blue: #3498d1;
$plblue: #3498d1;
// blue
$prospera-blue: #216085;
$pblue: #216085;
// dark blue
$prospera-dark-blue: #0E2837;
$pdblue: #0E2837;
$prospera-tertiary: #0E2837;
// light green
$prospera-light-green: #A8Bd9E;
$plgreen: #A8Bd9E;
// bright green
$prospera-bright-green: #009444;
$pbgreen: #009444;
// green - same as primary
$prospera-green: #7d9C6E;
$pgreen: #7d9C6E;
$prospera-primary: #7d9C6E;
// darker green
$prospera-dark-green: #69825C;
$pdgreen: #69825C;
$prospera-secondary: #69825C;
// primary same as prospera green
$kit-color-primary: #7d9C6E;
$primary-color: $kit-color-primary;
$primary: $kit-color-primary;
// secondary same as prospera dark green
$kit-color-secondary: #69825C;
$secondary-color: $kit-color-secondary;
$secondary: $kit-color-secondary;
// tertiary same as prospera dark blue
$kit-color-tertiary: #0E2837;
$tertiary-color: $kit-color-tertiary;
$tertiary: $kit-color-tertiary;
// dark color for accent
$prospera-dark: #303030;
$prospera-dark-1: #B3B3B3;
$prospera-dark-2: #737373;
$prospera-dark-3: #262626;
$prospera-dark-4: #333333;
$prospera-dark-5: #1A1A1A;
// dark theme
$primary-color-dark: #333333;
$secondary-color-dark: #1A1A1A;
$tertiary-color-dark: #216085;

// border
$gray-border: #e4e9f0;
$gray-border-lighten: #c0c0c0;
$gray-border-lighten-more: #dcdcdc;

$gray-darken: #b8beca;
$gray-lighten-more: #f2f4f8;
$orange-dark: #fc8b20;
$orange-light: #ffa617;
$red-more: #dc3545;
$gray: #d2d9e5;
$menuBg-darken: #fff;
$warning-more: #eb8519;
$blue-lighten: #2a95dc;
$light-pink: #bf83a0;
$primary-dark: #2a8f0c;
$black-lighten: #222034;

$lighter-font: #637280;
$app-brand-color: $primary-color;

// white color
$white-1: #fff;
// green color
$green-1: #7d9c6e;
$green-2: #51a629;
$green-3: #009444;

// red color
$red-1: #f00;

// gray color
$gray-0: #bbbbbb;
$gray-1: #d3d3d3;
$gray-2: #d3d3d3;
$gray-3: #262626;
$gray-4: #707070;
$gray-5: #928c84;
$gray-6: #928b83;
$gray-7: #35322E;
$gray-8: #272522;

// blue color
$blue: #2091fc;
$blue-1: #57657b;
$blue-2: #323c47;
$blue-light: #3498d1;
$blue-lighten: #2a95dc;
$blue-dark: #2c60e4;

// base Colors
$white: #fff;
$black: #000;
$yellow: #ff0;
$orange: #faad15;
$red: #d63939;
$pink: #fd3995;
$purple: #652eff;
$green: #41b883;
/* ----  text  --- */
$text: $prospera-dark-gray;
$text-lighten: #c0bdd0;
$dark-text: #e4e9f0;
$text-darken: #615d7c;
$text-darken-more: #131a25;



// or gray-6
$border: $gray-2;

// gradient button color mixin
@mixin button-gradient {
  background-image: linear-gradient(180deg, $orange-dark 0%, $orange-light 100%);
}

// Gradients color
@mixin nav-gradient {
  background-image: linear-gradient(
                  194deg,
                  #145629 0%,
                  #22652a 6%,
                  #3b802c 20%,
                  #4f952d 35%,
                  #5da42f 51%,
                  #65ad2f 70%,
                  #68b030 100%
  );
}

// system color
$default: $gray-4;
$info: $blue-light;
$success: $green;
$danger: $red;
$warning: $orange;
$light: $gray-1;
$dark: $black;
// dark theme
$dark-gray-1: #aeaee0;
$dark-gray-2: #7575a3;
$dark-gray-3: #4f4f7a;
$dark-gray-4: #232135;
$dark-gray-5: #141322;
$dark-gray-6: #0c0c1b;
$dark-gray-7: #3B3B3BFF;


// Shadows
$shadow: 0 0 40px -10px rgba($black, 0.2);
$shadow-2: 0 4px 38px 0 rgba($black, 0.11), 0 0 20px 0 rgba($black, 0.05);
$shadow-3: 0 0 100px -30px rgba(57, 55, 73, 0.3);
$shadow-4: 0 4px 10px 0 rgba($black, 0.03), 0 0 10px 0 rgba($black, 0.02);
$shadow-5: 0 0 40px -10px rgba($black, 0.4);
$shadow-ant: 0 10px 35px -5px rgba(0, 0, 0, 0.15);

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // @return #{$px}px; // to PXs
}

// Transitions
@mixin transition-bg() {
  transition: background 0.2s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}



.ant-menu-submenu-selected {
  color: $white;
  background-color: $primary-color;
}

@mixin fa-icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
}

@mixin fa-icon-rotate($degrees, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation})";
  transform: rotate($degrees);
}

@mixin fa-icon-flip($horiz, $vert, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation}, mirror=1)";
  transform: scale($horiz, $vert);
}


// Only display content to screen readers. A la Bootstrap 4.
//
// See: http://a11yproject.com/posts/how-to-hide-content/

@mixin sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

@mixin sr-only-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$xs-max-width: 800px;
$lg-min-width: 992px;
$lg-max-width: 991px;
$md-min-width: 768px;
$md-max-width: 767px;
$sm-min-width: 576px;
$sm-max-width: 575px;
$xs-min-width: 574px;