@import '../../assets/mixins';
// todo this image should be global
.loadingBg {
  height: 100vh;
  background-image: url('/resources/images/brand/prospera/background/LoadingBackground.png');

  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  position: relative;
}

//prospera logo banner loading with sunset
.loaderWrapper {
  position: absolute;
 display: flex;
  flex-direction: column;
  margin: auto;
 // padding: 2rem;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .prosperaLogoImage {
    min-width: 20rem;
    max-width: 35rem;
    margin: 0 0 0 6rem;
    display: block;
  }
}
/*
.header {
  margin: auto;
  padding: 0 0 0 5rem;
  text-align: center;
}
*/

.main {
  // color: $gray-7;
  margin: auto;
  padding: 2rem;
  text-align: center;

}
.progressBarWrapper {
  transform: rotate(270deg);

  :global(.ant-progress-inner) {
    background-color: #ffffff30; //00 is 100
  }
}
// @todo make this responsive
.progressBar {
  margin: 5px;  //needs this
}
.progressBarOne {
  width: 86px;
}
.progressBarTwo {
  width: 112px;
}
.progressBarThree {
  width: 136px;
}
.progressBarFour {
  width: 166px;
}
.progressBarFive {
  width: 196px;
}
.progressBarSix {
  width: 226px;
}
.loader {
  width: 200px;
  margin: 55px auto 0;
}

// message
.loadingMessage {
  color: $white;
  padding: 2rem;
  text-align: center;
  font-weight: 500;
  font-size: 2rem;
  margin: auto;
}

