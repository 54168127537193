@import '../../assets/mixins';

.ant-layout-content {
  background: $white;
  .utils__content {
    padding: 2.5rem 1.5rem 0 1.5rem;
    max-width: 100%;
  }
}
// header
.layoutHeader {
  position: fixed;
  padding: 0 1%;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  background-color: $white; // small gap on header
  border-bottom:  solid $pdgray  1px;
}
.layoutContent {
  height: 100%;
 // width: 100%;
  position: relative;
  margin-top: 64px;
  color: $text;
}

.background,
.backgroundOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
 // height: 100%;
}

.backgroundOverlay {
  background-image: linear-gradient(
    180deg,
    var(--background-overlay-top),
    var(--background-overlay-bottom)
  );
  // opacity: var(--background-opacity);
  opacity: 5%;
}

.layoutBackground {
  background-image: url('/resources/images/brand/prospera/background/MainAppBackground2.png');
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
}

.investorBackground {
 // background-image: url('/resources/images/brand/prospera/background/MainAppBackground.png');
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
}

.content {
  z-index: 3;
  position: relative;

  :global(.ant-layout) {
    background: none;
  }

  :global(.ant-layout-content) {
    background: none;
  }
}
