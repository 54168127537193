$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$xs-max-width: 800px;
$lg-min-width: 992px;
$lg-max-width: 991px;
$md-min-width: 768px;
$md-max-width: 767px;
$sm-min-width: 576px;
$sm-max-width: 575px;
$xs-min-width: 574px;


$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

$breakpoints: (
  xxl-min-width: 1600px,
  xxl-max-width: 1599px,
  xl-min-width: 1200px,
  xl-max-width: 1199px,
  xs-max-width: 800px,
  lg-min-width: 992px,
  lg-max-width: 991px,
  md-min-width: 768px,
  md-max-width: 767px,
  sm-min-width: 576px,
  sm-max-width: 575px,
  xs-min-width: 574px
);


$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
