@import '../color/colors';
@plugin 'AntdThemeLoader';
apply("s2n");

[data-kit-theme="s2n"] {

  // @import '../color/color';

  @kit-white: #ffffff;
  @kit-black: #141322;
  @kit-gray-1: #f2f4f8;
  @kit-gray-2: #e4e9f0;
  @kit-gray-3: #d3d3d3;
  @kit-gray-4: #c0c0c0;
  @kit-gray-5: #a9a9a9;
  @kit-gray-6: #808080;
  @kit-gray-7: #707070;
  @kit-gray-8: #5a5a5a;
  @kit-gray-9: #262626;
  @kit-blue: #4b7cf3;
  @kit-blue-light: #3498d1;
  @kit-red: #d63939;
  @kit-green: #009444;
  @kit-green-1: #7d9c6e;
  @kit-orange: #fc8b20;
  @danger: #d63939;
  @white: #ffffff;
  @primary-color: #7d9c6e;
  @primary: #7d9c6e;
  @prospera-6: @primary;
  @primary-color: @primary;
  @primary-color: #7d9c6e;
  @primary-color-dark: #496F85;
  @primary-dark-color: #496F85;
  @primary-color-hover: color(~`colorPalette('@{primary-color}', 5) `);
  @primary-color-active: color(~`colorPalette('@{primary-color}', 7) `);
  @primary-color-outline: fade(@primary-color, @outline-fade);
  --kit-color-primary: @primary;
  --primary-color: @primary;
  --primary: @primary;
  @secondary: #69825C;
  @secondary-color: #69825C;
  @secondary-color-dark: #2D4452;
  @secondary-dark-color: #2D4452;
  --kit-secondary-color: @secondary;
  --secondary-color: @secondary;
  @tertiary: #0E2837;
  @tertiary-color: #0E2837;
  --tertiary-color: @tertiary-color;
  --tertiary: @tertiary-color;
  @pgreen: @primary; //0E2837
  @pdgreen: @secondary;
  @porange: @kit-orange;
  @plgreen: #a8bd9E;
  @pgreen: #7d9C6E;
  @pdgreen: #69825C;
  @pbgreen: #009444;
  @plblue: #3498d1; //
  @pblue: #216085;
  @pdblue: #0E2837;
  @plgray: #d3d3d3;
  @pgray: #949494;
  @pdgray: #707070;
  @prospera-primary: @pgreen;
  @prospera-secondary: @pdgreen;
  @pblue: @prospera-blue;
  @plgray: @prospera-light-gray;
  @pdgray: @prospera-dark-gray;
  @text-color: #333333;
  @text: @text-color;
  @text-color-secondary: @pdgray;
  @text-color-inverse: @white;
  @icon-color: inherit;
  @font-family: 'Open Sans' Raleway, 'Permanent Marker', 'Shree Devanagari';
  @primary-font-family: 'Open Sans';
  @primary-font-family: '--primary-font-family';
  @secondary-font-family: '--secondary-font-family';
  @tertiary-font-family: '--tertiary-font-family';
  @prospera-blue: #0E2837;
  @prospera-light-blue: #3498d1;
  @prospera-dark-blue: #262626;
  @prospera-orange: #FC8B20;
  @prospera-primary: #7d9c6e;
  @prospera-secondary: #748e67;
  @prospera-light-gray: #d3d3d3;
  @prospera-dark-gray: #707070;
  @kit-blue-light: #3498d1;

  @kit-green: #41b883;
  @kit-orange: #E37D1E;

  @selection: @danger;
  @selected-color: @danger;

  @info-color: #5bc0de;
  @success-color: #41B883;
  @processing-color: @kit-orange; //default had kit-blue
  @error-color: @kit-red;
  @highlight-color: @kit-red;
  @warning-color: @kit-orange;
  @normal-color: @kit-gray-3;

  // Menu
  // ---
  //  @menu-inline-toplevel-item-height: 40px;
  @menu-item-height: 40px;
  // @menu-item-group-height: @line-height-base;
  @menu-collapsed-width: 80px;
  @menu-bg: @secondary;
  @menu-popup-bg: @component-background;
  @menu-item: @secondary;
  @menu-item-color: @white;
  @menu-item-background: @secondary-color;
  @menu-inline-submenu-bg: @primary;
  @menu-highlight-color: @primary-color;
  @menu-highlight-danger-color: @danger;
  @menu-item-active-bg: @primary-color;
  @menu-item-active-danger-bg: @danger;
  @menu-item-active-border-width: .7rem;
  //  @menu-item-group-title-color: $text;
  @menu-item-vertical-margin: 4px;
  @menu-item-font-size: 1.2rem;
  @menu-item-boundary-margin: 8px;
  @menu-item-padding-horizontal: 20px;
  @menu-item-padding: 0 @menu-item-padding-horizontal;
  @menu-horizontal-line-height: 46px;
  // @menu-icon-margin-right: 10px;
  @menu-icon-size: @menu-item-font-size;
  @menu-icon-size-lg: @font-size-lg;
  @menu-item-group-title-font-size: @menu-item-font-size;

  @body-background: @kit-white;
  @component-background: @kit-white;
  @heading-color: @kit-gray-6;
  // layout
  @layout-header-background: @kit-gray-2;
  @layout-footer-background: @kit-gray-2;
  @layout-footer-padding: 1rem 2rem;
  @layout-trigger-background: @prospera-light-blue;
  @layout-trigger-color: #fff;
  @layout-zero-trigger-width: 36px;
  @layout-zero-trigger-height: 42px;
  @layout-body-background: transparent; // #f0f2f5;
  @layout-header-height: 64px; // wth wasn't here before
  @layout-header-padding: 0 0 !important; // wth wasn't there before

  // @border-color-base: @kit-gray-2;
  // @border-color-split: @kit-gray-2;
  @border-color-inverse: @kit-white;
  @border-radius-base: 4px;
  @disabled-color: @kit-gray-4;
  @disabled-bg: @kit-gray-2;

  @btn-primary-color: #fff; //not disabled
  @btn-primary-border: @tertiary;
  @btn-primary-bg: @tertiary; //had kit-blue
  @btn-default-colors: @primary; //not disabled
  @btn-default-border: @primary;
  @btn-hover-bg: @primary;
  @btn-default-bg: @kit-white;

  @btn-danger-color: @kit-white;
  @btn-danger-bg: @kit-red;
  @btn-danger-border: @kit-red;
  // links
  // @link-color: @kit-blue; //kit-blue

  @link-active-color: @kit-blue-light;

  @item-active-bg: @primary-color;
  @item-hover-bg: @primary-color;

  // prosper
  @link-color: @primary-color;
  @link-hover-color: color(~`colorPalette('@{link-color}', 5) `);
  @link-active-color: color(~`colorPalette('@{link-color}', 7) `);
  @link-decoration: none;
  @link-hover-decoration: none;
  @link-focus-decoration: none;
  @link-focus-outline: 0;


  @input-placeholder-color: lighten(@kit-gray-6, 10%);
  @input-color: @kit-gray-7; //gray 6
  @input-addon-bg: @kit-gray-1;
  @input-bg: @kit-white;
  @input-border-color: @kit-gray-2;

  @alert-success-border-color: @success-color;
  @alert-success-bg-color: #fffbe6;
  @alert-success-icon-color: @success-color;
  @alert-info-border-color: #5bc0de;
  @alert-info-bg-color: #e6f7ff;
  @alert-info-icon-color: #5bc0de; //blue
  @alert-warning-border-color: @kit-orange;
  @alert-warning-bg-color: #fffbe6;
  @alert-warning-icon-color: @kit-orange;
  @alert-error-border-color: @kit-red;
  @alert-error-bg-color: #fffbe6;
  @alert-error-icon-color: @kit-red;

  @tabs-hover-color: @primary;
  @tabs-active-color: @primary; //blue
  @tabs-card-active-color: @primary; //blue
  @tabs-ink-bar-color: @kit-green-1; //blue
  @tabs-highlight-color: @info-color; //blue
  // table header color
  // @table-header-bg: @prospera-dark-gray; removed to try green
  @table-header-bg: @secondary-color;
  @table-header-color: #fff;
  @table-footer-bg: @kit-gray-1;
  @table-footer-color: @kit-gray-6; // huh?
  @table-row-hover-bg: @kit-gray-2;
  @table-selected-row-bg: @kit-gray-5;
  @table-expanded-row-bg: @kit-gray-2;

  @label-required-color: @kit-red;
  @label-color: @kit-gray-9;

  // not sure why the below? not in antd

  @form-item-label-font-size: 16px;
  @form-item-ant-select: 16px;
  @form-item-ant-input: 16px;
  @font-size-base: 16px;
  @font-size-lg: @font-size-base + 6px;
  @font-size-sm: 12px;

  // LINK
  @link-color: @primary-color;
  @link-hover-color: color(~`colorPalette('@{link-color}', 5) `);
  @link-active-color: color(~`colorPalette('@{link-color}', 7) `);
  @link-decoration: none;
  @link-hover-decoration: none;
  @link-focus-decoration: none;
  @link-focus-outline: 0;

  @layout-trigger-background: @tertiary-color;


  // Outline
  @outline-blur-size: 0;
  @outline-width: 2px;
  @outline-color: @tertiary-color; // No use anymore
  @outline-fade: 20%;


  // Checkbox
  @checkbox-size: 16px;
  @checkbox-color: @tertiary-color;


  // Divider
  @divider-text-padding: 1em;
  @divider-orientation-margin: 5%;
  @divider-color: rgba(0, 0, 0, 6%);
  @divider-vertical-gutter: 8px;

  // Media queries breakpoints
  // @screen-xs and @screen-xs-min is not used in Grid
  // smallest break point is @screen-md
  @screen-xs: 480px;
  @screen-xs-min: @screen-xs;
  // 👆 Extra small screen / phone

  // 👇 Small screen / tablet
  @screen-sm: 576px;
  @screen-sm-min: @screen-sm;

  // Medium screen / desktop
  @screen-md: 768px;
  @screen-md-min: @screen-md;

  // Large screen / wide desktop
  @screen-lg: 992px;
  @screen-lg-min: @screen-lg;

  // Extra large screen / full hd
  @screen-xl: 1200px;
  @screen-xl-min: @screen-xl;

  // Extra extra large screen / large desktop
  @screen-xxl: 1600px;
  @screen-xxl-min: @screen-xxl;

}
@root-entry-name: default;@primary-color: #7d9c6e;@primary-font: Open Sans;@link-color: #A8Bd9E;@secondary-color: #69825C;@background-color: #ffffff;