@import '../../../mixins';
// this is bootstrap btn
/*
.btn-primary {
  background-color: $white;
  border: 1px solid var(--kit-color-primary) !important;
  border-radius: 4px !important;
  color: $black !important;
  margin: 3px !important;
  &:hover,
  &:focus,
  &:active {
    background: var(--kit-color-primary) !important;
    border: 1px solid var(--kit-color-primary) !important;
    color: $white !important;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: var(--kit-color-primary) !important;
    border: 1px solid var(--kit-color-primary) !important;
    color: $white !important;
  }
}
*/


/*
.ant-btn-primary {
  color: #fff !important;
  border-color: $pgreen !important;
  background: $pgreen;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
*/




// text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
//  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);


// this is for the nav buttons at top of app for switching
.ant-btn-circle {
  color: $secondary-color !important;
  border-color: $secondary-color !important;
  background: $white !important;
  border: 3px solid currentColor;
  border-radius: 50%;
  font-family: 'Permanent Marker', sans-serif;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
  overflow: hidden;
  padding: 1px 1px;
  position: relative;
  text-decoration: none;
  transition: .2s transform ease-in-out;
  will-change: transform;
  z-index: 0;
}
.ant-btn-circle:after {
  // border-color: $pgreen;
  background-color: $pgreen;
  border-radius: 50%;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: .2s transform ease-out;
  will-change: transform;
  z-index: -1;
}
  .ant-btn-circle:hover::after {
    transform: translate(0, 0);
  }
.ant-btn-circle:hover {
        border: 1px solid transparent;
        color: $white !important;
        transform: scale(1.05);
        will-change: transform;
      }

/*
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
*/
/*
.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
*/

// .btn-gray {
//   background-color: var(--default);
//   border: 1px solid var(--default) !important;
//   border-radius: 4px !important;
//   color: $white !important;
//   margin: 3px !important;
//   &:hover,
//   &:focus,
//   &:active {
//     background: var(--default) !important;
//     border: 1px solid var(--default) !important;
//     color: $white !important;
//   }
//   &:not(:disabled):not(.disabled):active {
//     background-color: var(--default) !important;
//     border: 1px solid var(--default) !important;
//     color: $white !important;
//   }
// }

/*
.btn-default {
  border: 1px solid var(--kit-color-primary);
  color: var(--kit-color-primary);
  margin: 3px;
  &:hover,
  &:focus,
  &:active {
    color: var(--primary-dark);
    border: 1px solid var(--primary-dark);
  }
}
*/
.btn-danger {
  background-color: $red !important;
  border: 1px solid $red !important;
  border-radius: 4px !important;
  color: $white !important;
  margin: 3px !important;
  &:hover,
  &:focus,
  &:active {
    background: $red-more !important;
    border: 1px solid $red-more !important;
    color: $white !important;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: $red-more !important;
    border: 1px solid $red-more !important;
    color: $white !important;
  }
}

.btn-warning {
  background-color: $warning !important;
  border: 1px solid $warning !important;
  border-radius: 4px !important;
  color: $white !important;
  margin: 3px !important;
  &:hover,
  &:focus,
  &:active {
    background: $warning-more !important;
    border: 1px solid $warning-more !important;
    color: $white !important;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: $warning-more !important;
    border: 1px solid $warning-more !important;
    color: $white !important;
  }
}




.btn-orange {
  background-color: $porange;
}

.btn-blue {
  background-color: $pblue;
}

.btn-gray {
  background-color: $gray-7;
}

.btn-green {
  background-color: $prospera-green;
}

.btn-gray-1 {
  background-color: $gray-7;
}

.btn-shadow {
  box-shadow: 4px 4px 2px $gray-border-lighten-more !important;
}

.btn-curved {
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 0.25px $gray-5 solid;
  padding: .25rem 1.5rem;
  cursor: pointer;
  color: $white;
  font-weight: bold;
  font-size: 1.1rem;

  &:focus {
    outline: 0;
  }
  &:active {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    transform: translateY(4px);
  }
}

.addButton {
  &:hover {
    color: #428c3f;
  }
}

.plusIcon {
  color: #428c3f;
}

.btn-round {
  border-radius: 5px;
  border: 0;
  color: $white;
}
// from antd css

/*
  .ant-btn {
    &:hover,
    &:active,
    &:focus {
      border-color: lighten($pdgreen, 0%);
      color: lighten($plgreen, 0%);
    }
    &-primary {
      background: $prospera-green !important;
      border-color: $prospera-dark-green !important;
      &:hover,
      &:active,
      &:focus {
        background: lighten($prospera-dark-green, 10%) !important;
        border-color: lighten($prospera-dark-green, 0%) !important;
        color: $white;
      }
    }
    &-pblue {
      background: $prospera-blue !important;
      border-color: $prospera-blue !important;

      &:hover,
      &:active,
      &:focus {
        background: lighten($prospera-blue, 20%) !important;
        border-color: lighten($prospera-blue, 0%) !important;
        color: $white;
      }
    }
    &-porange {
      background: $prospera-orange !important;
      border-color: $prospera-orange !important;

      &:hover,
      &:active,
      &:focus {
        background: lighten($prospera-orange, 5%) !important;
        border-color: lighten($prospera-orange,0%) !important;
        color: $white;
      }
    }
    &-pdgray {
      background: $pdgray !important;
      border-color: $black !important;

      &:hover,
      &:active,
      &:focus {
        background: lighten($pdgray, 10%) !important;
        border-color: lighten($black, 0%) !important;
        color: $white;
      }
    }
    &-danger {
      &:hover,
      &:active,
      &:focus {
        background: lighten($danger, 25%);
        border-color: lighten($danger, 0%);
        color: $white;
      }
    }
  }
*/
////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-btn {
    &-primary {
      &:hover,
      &:active,
      &:focus {
  //      background: lighten($blue-light, 0%) !important;
  //      border-color: lighten($blue-light, 0%) !important;
        color: $white;
      }
    }
    &-danger {
      &:hover,
      &:active,
      &:focus {
        background: lighten($danger, 10%);
        border-color: lighten($danger, 10%);
        color: $white;
      }
    }
  }
}
