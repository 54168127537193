// todo move this up one folder
@import '../../../../../assets/abstracts/breakpoints';


.twoColumnLayout {
  display: flex;
  justify-content: space-between;
  width: 88%;
}
// patch this was 75 b4

.multiFormItem {
  display: flex;

}
.multiFormItemState {
  display: flex;
  width: 100%;
}
.searchAndSelectField {
  min-width: 32rem;
}

.newInvestor {
  font-size: 16px;
  padding-top: 15px;

  .ant-form-item-label > label::after {
    content: none;
  }
}
.twoFormItems {
  display: flex;
  justify-content: space-between;
}

.phoneNumberField {
  width: 13rem;
}

.inlineFormItem {
  margin-left: 2rem;
  position: relative;
  display: inline-flex;
  align-items: center;
}

.postalCodeField {
  width: 13rem;
}

.inlineStateField {
  width: 26rem;
  position: relative;
  display: inline-flex;
  align-items: flex-end;

}

.formLayout {
  width: 88%;
  min-width: $xs-min-width;
  margin-bottom: 1rem;
}

.mailingAddressWrapper {
  display: flex;
}

.isExistingInvestorBlock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.physicalAddressWrapper,
.managedAccountWrapper {
  :global(.ant-form-item-control-input-content) {
    margin-left: 0;
  }
}
