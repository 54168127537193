// @import '../../../../assets/mixins'; // our mixin
////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-checkbox {
    font-size: 16px;
    &-inner {
      background-color: $gray-6;
    }
    &-checked {
      .ant-checkbox-inner {
        background: $primary;
      }
    }
    &-disabled {
      .ant-checkbox-inner {
        border-color: $dark-gray-4 !important;
        opacity: 0.8;
      }
    }
  }
}
