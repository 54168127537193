@import '../../../assets/mixins';

.justifyContentSpaceBetween {
  display: flex;
  justify-content: stretch;
}
.title {
  padding: 1% 1% 1% 2%;
}
.instruction {
  padding: 1% 1% 1% 0;
}
.outline {
  background-color: white;
  border-radius: rem(10);
  margin: 0% 0.5% 2% 2%;
  width: 70%;
}
.taxid {
  max-width: 33%;
}
.header {
  text-align: left;
  border-bottom: solid #928c84 4px;
  width: 100%;
}
.radio {
  display: block;
  height: 30px;
  line-height: 30px;
}
.form {
  padding: 1vw 2vw 0 2vw;
}
.sidebox {
  padding: 1vw;
  position: absolute;
  right: 2vw;
  top: 11vh;
  width: 30%;
  border-radius: rem(10);
  border: solid black 1px;
}

.graybtn {
  background-color: #0e2837;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-color: #0e2837;
  border-style: solid;
  border-width: 0.25px;
  // width: 125px;
  // padding: 2px;
  padding: 0.25vw 1vw;
  margin: 1vw;
  cursor: pointer;
  color: $white;
  box-shadow: 4px 4px 2px $gray-border-lighten-more !important;
  &:hover {
    background-color: #0e2837;
    color: $dark-gray-1;
    border-color: #0e2837;
  }
}
.button {
  border-color: black;
}

.phoneType {
  vertical-align: center;
  margin: 10px 0 0 10vw;
}

.amount {
  width: 10vw;
}

.contribution {
  width: 16vw;
}

.bttm {
  margin-top: 2vh;
  text-align: left;
}

.bttmrow {
  margin-top: 2vh;
  margin-right: -30px;
  text-align: right;
}

.field {
  margin-top: 1vh;
  margin-bottom: -1vh;
}

.paragraph {
  margin-left: 25px;

  strong {
    font-weight: 700;
  }
}

.bttn {
  border-color: #928c84;
}

.addItem {
  color: #928c84;
  padding-bottom: 10px;
}

.sidefield {
  margin-top: -2vh;
}

.centerCard {
  padding: 0% 10% 30% 10%;
}

.logo {
  margin: 5% 0%;
  width: 300px;
  height: 65px;
}

.logoContainer {
  text-align: center;
}

.date {
  text-align: right;
}

.close {
  margin-top: 8px;
  background: none;
  border: none;
  outline: none !important;
  cursor: pointer;
}

.exit {
  text-align: right;
  color: #928c84;
  padding-bottom: rem(20);
  margin-bottom: rem(20);

  :global(.fa) {
    float: right;
    cursor: pointer;
    padding: 4px 2px;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}

.signature {
  margin-left: 45%;
}

.investorInvitationWrapper {
  width: 50%;
}

.investorInvitationForm {
  :global(.ant-row) {
    margin-right: .5rem;
    display: block;
    align-items: center;
  }

  input {
    border-radius: 5px !important;
  }
/*
  h3 {
    margin-bottom: 15px;
  }
*/
  :global(.ant-select-selection-item) {
    color: $gray-6;
  }
}

.expectedContributionInput {
  width: 200px;
}

.investorNameInputWrapper {
  display: flex;
}

.titleInput {
  input {
    width: 97px !important;
  }
}

.advisorFieldInput {
  :global(.ant-select) {
    width: 143px !important;
  }
}

.otherContactsFieldsWrapper {
  display: flex;
}

.formActionWrapper {
  display: flex;
  justify-content: space-between;
}

.formActionWrapperSingleButton {
  display: flex;
  justify-content: flex-end;
}

.saveButton {
  background-image: none;
  background-color: $gray-3;
  border-color: $gray-4;
  margin-right: 20px;
}

.previousButton {
  position: absolute;
  left: 20px;
  background-image: none;
  background-color: $gray-3;
  border-color: $gray-4;
  margin-right: 20px;
}

.addButton {
  &:hover {
    color: #428c3f;
  }
}

.plusIcon {
  color: #428c3f;
}

.taxAdvisorWrapper {
  display: flex;
  align-items: center;

  :global(.ant-input) {
    width: 100px;
  }
}

.accreditationVerificationFormWrapper {
  h3 {
    margin-bottom: 0;
  }

  :global(.ant-radio-wrapper) {
    display: flex;
    align-items: center;
  }
}
.entityPoints {
  p {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
  }
}
.DOB {
  width: 100%;
}
.questionnaireWrapper{
  overflow-wrap: break-word;
}
.signatureWrapper {
  // font-size: 1.2rem;
  float: right;
  width: 40%;
  .signed {
    font-family: $primary-font-family;
    font-size: 1.2rem;
  }
  p,
  {
    margin: 7px;
    font-size: 2rem;
    font-family: 'Permanent Marker', Serif;
  }
}
