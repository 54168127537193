@import '../../assets/mixins';

.menu {
  background: $gray-lighten-more !important;
  .ant-layout-sider-children {
    height: 100%;
    width: 16rem;
    transition: width 0.3s;
  }

  .ant-layout-sider-zero-width-trigger {
    top: .25rem;
    height: 30px;
    line-height: 2rem;
  }

  .ant-menu {
    background: $white;
    border-radius: 2rem;
  }
}
// remove global
.navigation {
  .ant-menu-item {
    &-menu-item-selected {
      background-color: $primary-color;
      color: $white;
      border-radius: 1rem;
      a {
        color: $white;
        .icon {
          color: $white !important;
        }
      }
    }
  }
}
// chevrons in menu ant-menu-submenu-arrow
.tabIcon {
  i {
    margin-right: .9rem;
  }
}
/*
 li class ant-menu-item ant-menu-item-only-child ant-menu-item-selected {
   border-radius: .5rem;
 }
*/
.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  display: flex;
  align-items: center;
  border-radius: .5rem;
}


/*
 .ant-menu .ant-menu-light,
 .ant-menu-root .ant-menu-inline .menu-item {
   background: $secondary-color;  // $plgray;
   border-radius: .5rem;
 }

  .ant-menu-submenu {
    background: $secondary-color;
    border-radius: .5rem;
  }
*/

.centeredCard {
  flex: auto;
  background-color: #fff;
  border-radius: .5rem;
  margin-top: -100px;
  margin-left: 20%;
  padding: 2rem;
  max-height: 28rem;
  overflow: auto;
}
