// @import '~bootstrap/dist/css/bootstrap.min.css';
// should add fusioncharts api in the future
// this order must be followed!
// dont mess with it
@import 'assets/base';
@import 'assets/abstracts';
// ANTD
@import 'assets/components/antd/antdStyle';
// BOOTSTRAP
@import 'assets/components/bootstrap/bootstrapStyle';
// font-awesome
@import 'assets/fonts/font-awesome/scss/fontawesome';

// VENDORS
@import 'assets/vendors/perfect-scrollbar/style';
@import 'assets/vendors/nprogress/style';
@import 'assets/vendors/chartist/style';
@import 'assets/vendors/chartist-tooltip-plugin/style';
@import 'assets/vendors/jvectormap/style';
@import 'assets/vendors/react-sortable-tree/style';
@import 'assets/vendors/react-draft-wysiwyg/style';

//  BASE typography, style sheet, color
// @import 'base/reset';


//  ROUTES and LAYOUT
// @import 'assets/styles/core/coreStyle'; // core UI KIT styles
// @import 'assets/styles/sassStyle'; //  Styles Sass
// removal of it will cause what?
@import './assets/base/_measurements';
// @import 'assets/abstracts/_colors';
@import './assets/abstracts/_utils';

// Abstracts
// @import 'assets/color/color';
// Routes and Layouts
// @import 'assets/base';

// S2N styles
@import 'assets/components/s2n/_s2nStyle';  // this is only component css
// @import 'assets/base';

// PAGES
// @import 'pages/home';
// @import 'pages/contact';
// THEMES
// @import 'themes/theme';
// @import 'assets/themes/default.less';



