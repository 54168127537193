@import '../../assets/mixins';

/*
.footer {
  background-color: #a8a9aa;
 // width: 100%;
  // height: 2rem;
*/

  // background-color: $pdgray;
  // color: $white;
  // background: $pdgray;

//  background: $prospera-dark-gray;

footer {
  position: fixed;
}

.footer {
  width: 100%;
  // padding-bottom: 0px;
  // bottom: 0px;
  line-height: 0;
}

.footerIcons {
  text-align: center;
  list-style-type: none;

  li {
    display: inline-block;
    &:nth-of-type(n+2) {
      margin-left: 12px;
    }
  }

  a {
    padding: 8px 0;
    display: block;
    width: 56px;
    text-align: center;
    color: #fff;
    border: 0 solid;
    border-radius: 50%;
    transition: opacity .235s ease-in-out;

    &:hover {
      opacity: .91;
    }
  }

  .fab {
    vertical-align: middle;
    font-size: 48px;
  }
}

.copyright {
  text-align: center;
  position: relative;
  margin: 0;
  color: $text;
  background: transparent;
  // bottom: 8px;

}

.ant-layout-footer {
  background-color: #323C47;
}
