// @import '../../../../assets/mixins'; // our mixin

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-empty {
    svg * {
      fill: $gray-5;
      stroke: $gray-6;
    }
  }
}
