@import '../../../mixins'; // our mixin
// @import '../../../styles/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* CAROUSEL */

.carousel-indicators {
  li {
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
  }
}

// dark theme
[data-kit-theme='dark'] {
  .carousel-indicators {
    li {
      &.active {
        // opacity: 0;
        background-color: $primary;
      }
    }
  }
}
