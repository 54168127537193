@import '../../../assets/mixins';

.bottomLine {
  padding: 0.5vw;
  vertical-align: bottom;
  border-bottom: solid 5px #ddd;
}

.title {
  margin-top: 2%;
}
// Form core
.content {
  text-align: right;
}
.textBlock {
  font-size: 1rem;
  text-align: left;
  margin: 1rem 0 0 .5rem;
}

.mainCard {
  padding: 1rem;
  width: auto;
  height: auto;
  overflow: auto;
}

.bttmrow {
  margin: 50px 0 0 380px;
}

.ant-form-item {
    display: flex;
  }

.ant-form-item-label {
    min-width: 105px;
    // float: left;
    white-space: nowrap;
  }
.ant-form-item-control-wrapper {
    // float: left;
    // width: calc(100% - 105px);
    width: 100%;
  }

.ant-col.ant-form-item-control {
    margin-right: 10px;
  }

.customLabelMin {
 .ant-form-item-label {
      min-width: 100px;

    }
  }

.customLabelMax {
  .ant-form-item-label {
      min-width: 170px;
    }
  }

