// @import '/../../../base/font-face';
@import '../../../mixins';


/* -----Heading typography ----- */
body {
  font-size: 16px;
  line-height: 1.48;
  font-family: $primary-font-family;
  color: $text;
  overflow-x: hidden;
  position: relative;
  font-variant: normal;
  font-feature-settings: normal;
}

a {
  text-decoration: none;
  color: $text;
  font-size: 1rem;
  font-family: $primary-font-family;
  @include transition-color();
  &:hover,
  &:active,
  &:focus {
    color: $blue;
    // background-color: $blue; // added
    text-decoration: none;
  }
}

// removed from base
/*
input {
  outline: none !important;
  font-family: 'Open Sans', 'sans-serif';
  color: $text;
}
 */
input {
  outline: none !important;
  font-family: $primary-font-family, sans-serif;
  color: $text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text;
}

h1 {
  text-indent: 0;
  font-size: 2rem;
  font-family: $primary-font-family;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;
}

h2 {
  text-indent: 0;
  font-size: 1.56rem;
  font-family: $primary-font-family, sans-serif;
  font-weight: bold;
  // text-transform: uppercase;
  overflow: hidden;
  margin-bottom: 0;
}

h3 {
  font-size: 1.32rem;
  font-weight: 600;
  font-family: $primary-font-family, sans-serif;
 // text-transform: uppercase;
  overflow: hidden;
  margin-bottom: 1rem;
}

 h4 {
  font-size: 1rem;
  font-family: $primary-font-family, sans-serif;
  overflow: hidden;
  margin-bottom: .25rem;
}

h5 {
  font-size: .88rem;
  font-family: $primary-font-family, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  overflow: hidden;
}

h6 {
  font-size: .75rem;
  font-weight: 400;
  font-family: $primary-font-family, sans-serif;
  overflow: hidden;
}


/*   removing this should break nothing
h15 {
  font-size: 1.65em;
  font-weight: 500;
  text-transform: uppercase;
  overflow: hidden;
  color: $black !important;
}
*/
/* -----Paragraph typography ----- */
p {
  // text-indent: .5rem;
  font-size: .99rem;
  font-weight: 300;
  font-family: $primary-font-family;
 // overflow: hidden;
 //  margin: .5rem;
  // padding-left: 2rem;
  // letter-spacing: -0.1;
  }

.p-dark {
  color: $gray-3;
}
.p-dark-bold {
  color: $gray-3;
  font-weight: bold;
}

.p-light {
  color: $blue-2;
}

.p-light-bold {
  color: $blue-2;
  font-weight: bold;
}

.bold {
  font-weight: 700;
}

// removed from base

button,
input {
  //   box-shadow: none !important; // do we want this on
  outline: none !important;
}
input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  appearance: none !important;
}


/* -----Table typography ----- */
table {
  thead tr th {
    font-size: 1.1rem;
    font-weight: 400 !important;
  }

  tbody tr td {
    font-size: .9rem;
  }
}

/* ----- Button typography ----- */
button {
  font-size: 1.2rem;
}

/* ----- Link typography ----- */

.italic,
i {
  font-family: $tertiary-font-family;
   font-style: italic;
}

// menu typography
.ant-menu-item {
  font-size: 1.1rem;
}

.ant-menu-item,
.ant-menu-inline,
.ant-menu-submenu-title {
  font-size: 1.1rem;
}

// breadcrumb typography
.breadcrumbPath {
  font-size: 1.24rem;
  background-color: inherit;
}

.handwriting {
  $tertiary-font-family: 'Permanent Marker', cursive;
  font-size: 48px;
  text-shadow: 4px 4px 4px #aaa;
}

// taken from base
/*
.badge-example {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  background: inherit;
  color: $black;
  display: inline-block;
  padding: .25rem .5rem;
  border-radius: 12px;
}
*/

.badge-example {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  background: $gray-2;
  // background: $dark-gray-4;
  color: $black;
  display: inline-block;
  padding: rem(3) rem(6);
  border-radius: 4px;
}
////////////////////////////////////
/* TYPOGRAPHY from bootstrap */

.mark,
mark {
  background: $orange;
  color: $white;
}

.blockquote {
  background: $gray-1;
  padding: 30px;
  border-left: 3px solid $border;
  font-size: rem(15);

  &-reverse {
    text-align: right;
    border-left: none;
    border-right: 3px solid $border;
  }
  &-footer {
    margin-top: rem(20);
    color: $gray-5;
  }
}

// dark-theme
[data-kit-theme='dark'] {
  .blockquote {
    background: $dark-gray-4;
  }
}