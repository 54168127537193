@import '../../assets/mixins';
// no display flex on .topbar, but yes on contents
.navBar {
  // background-color: transparent; //transparent
}

.topNavBar {
  padding: 0 0 0 20%; // move navs to the right i dont like it
  min-height: 64px;
  height: 64px;
  color: $text; // why this color
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid $plgray;
}

.appSwitcherNav{
// text-align: left;
  padding-left: 20px;
// display: flex;
// flex-wrap: nowrap;
// margin-right: 50%;
// width: 50%;
// float: left;
// justify-content: space-evenly;
// flex-direction: row;
  }

.profileMenuNav{
  margin-left: auto;
  font-size: 40px;
  //text-align: end;
  //display: flex;
  //flex-wrap: nowrap;
  // margin-left: 50%;
  // float: right;
  //justify-content: space-evenly;
  //flex-direction: row;
  // justify-content: flex-end;
   // flex-direction: row-reverse;
  }

.navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
//  background: #13c2c2;
  align-items: center;
  padding: 0;
  width: auto;
// border-bottom: 1px solid $gray-border;
// background-color: transparent; //transparent

}
