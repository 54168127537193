@import '../../../assets/mixins';

.menu {
  // border-right: 1px solid $gray-border;
  box-sizing: content-box;
  z-index: 11;

  @at-root {
    @media (min-width: 1435px) {
      .settings__fixedWidth .menu {
        border-left: 1px solid $gray-border;
      }
    }
  }

  /* --------START--------------
    DONT REMOVE, THESE GLOBAL STYLES ACT AS HOW REDUX WOULD ACT AT MAINTAINING THE GLOBAL STATE OF THE LEFT MENU
   */
  :global(.ant-layout-sider-children) {
    position: fixed;
    height: 100%;
    width: 256px;
    transition: width 0.3s;
  }

  &:global.ant-layout-sider-collapsed {
    :global(.ant-layout-sider-children) {
      width: 80px;
    }
  }

  /* --------END--------------
 */

  .ant-menu-inline-collapsed-tooltip .icon {
  display: none;
}
  :global(.ant-layout-sider-zero-width-trigger) {
    top: .625rem !important;
  }
  /* -----   menu-item ----- */
  .ant-menu-item,
  .ant-menu-submenu-title {
    background-color: $secondary-color; // should change the padding
    transition: padding 0s !important;
    font-weight: 300;
  }
}
/* ------  Menu end   ----- */
/* ------- Menu Logo and collapsed menu  ----- */
.logo {
  height: 4rem;
  background: $primary-color; // was var-second
}
/* -------  Menu Logo Container ----- */
.logoContainer {
  height: 4rem;
  padding-top: .6rem;
  background: $white;
  border-bottom: 1px solid $plgray;
  img {
    width: 14.3rem;
    display: block;
    margin: 0 auto;
  }
}
/* ------ Menu Logo-Emblem Collapsed  -------- */
.logoContainerCollapsed {
  height: 4rem;
  padding-top: .4rem;
  background: $white;
  border-bottom: 1px solid $plgray;
  img {
    width: 3.4rem;
    margin: 0 auto;
    display: block;
  }
}
/* -- menu icons so chevrons show -- */
.icon {
  text-align: center;
  position: absolute;
  right: 2.5rem;
  width: 1.2rem;
  top: .75rem;
  margin: 0 !important;
  line-height: 1 !important;
  color: $white;
}
/* -----------  NAV  --------- */
.navigation {
  > .ant-menu-item-divider {
    margin: 5.6rem 1rem 0 !important;
    height: .32rem;
    background-color: $white; // was originally white
  }
  .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow, {
    right: 3.125rem;
    // border-radius: .5rem;

  }
  .ant-menu-inline .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
  .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    box-shadow: none !important;
    transition: all 0.3s;
   //   background-color: $selected-color; // doesnt work
  }
// what is this global for
  &:global(.ant-menu-inline-collapsed) {
    .title {
      display: none;
    }
    .ant-menu-submenu, >
    .ant-menu-submenu-title,
    .ant-menu-submenu-arrow {
      display: block;
      opacity: 1%;
      top: 48%;
      right: .75rem;
    }
    .icon {
      right: 2rem; // not sure
    }
  }
  /* --------  chevron in menu ------- */
  .ant-menu-light {
    .icon {
      opacity: 0.8;
    }
    .ant-menu-submenu-arrow {
      opacity: 0.8;
    }
    .ant-menu-item:after {
      border-right: 0 solid $white !important;
      right: auto;
      left: 0;
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      width: 100%;
    }
  }
  /* ----- ----- new group ------- */ // dont think -default will work
  .ant-menu {
    color: $white;

    .ant-menu-item-divider {
      opacity: 1;
    }
    .ant-menu-sub {
      background: transparent !important;
      color: $gray-darken; // don't see it //previous $gray-darken
    }
    .ant-menu-item {
      color: $white;
      background-color: $secondary-color;
    >  a {
        color: $white;
        &:hover {
          .icon {
            color: $white !important;
          }
        }
      }
    }
    .ant-menu-item,
    .ant-menu-submenu {
      text-transform: uppercase;
    }
    .ant-menu-item-selected {
     > a {
        color: $white;
       background: $primary-color !important;
        .icon {
          color: $white !important;
        }
      }
    }
    .ant-menu-submenu:hover,
    .ant-menu-submenu-selected,
    .ant-menu-submenu-open {
      .icon {
        color: $white !important;
       background-color: $primary-color; // needed?
      }
    }
    // icon color for selected item in collapsed menu
    .ant-menu-submenu-active  {
      .icon {
        color: $white !important;
        // background-color: $primary-color !important;
      }
    }
    .ant-menu .ant-menu-inline .ant-menu-sub {
      box-shadow: none !important;
      transition: all 0.3s;
    }

    .ant-menu-item:after  {
      border-right: 0 solid $white !important;
      right: auto;
      left: 0;
    }
  }
  /* -------- nav selection in menu  ------- */
  .ant-menu-item {
    &-ant-menu-item-selected  {
      background-color: $primary-color;
      color: $white;
      border-radius: 1rem;
      a {
        color: $white;
      //  background-color: $primary;
      }
    }
  }
  /* ----- make selected title bolder ---- */
  .active {
    font-weight: 700;
  }
}
/* ------------            Nav END       --------- */
/* ----- ----- new css scrollbar, eval to remove  ------- */
.scrollbarDesktop {
  height: calc(100vh - 112px) !important;
  background: $secondary-color;
  .ant-input-suffix {
    display: none;
  }
  > div:first-child {
    background: $secondary-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
/* ----- ----- drawer and scroll bar ------- */

.scrollbarMobile {
  height: calc(100vh - 67px) !important;
}
/* ----- ----- new css should eval to remove ------- */

.light {
  background: $white;

  .ant-layout-sider-trigger {
    color: inherit;
    background: $secondary-color-dark;
  }
  .ant-menu-item {
    color: $text;
    &:hover {
      a {
        color: $white;
      }
      background-color: $primary-color;
    }
  }
  .drawer-content {
    background: $white;// !important; // why white
  }
}

/* ------ tooltip  it is ------- */
.ant-menu-inline-collapsed-tooltip [class*='style__icon'] {
  display: none;
}

/* ----- ----- @todo add this feature this to the top bar too ------- */
.searchField {
  color: $white;
  display: block;
  margin-bottom: 1.25rem;
}

/* -----  Color for all menu items ------- */
.title {
  color: $white;
}
/* -----  font weight for active menu does what? ------- */
.activeParent {
  font-weight: 700; // is not consistent @fixme
}
.menuSearchWrapper {
  margin: 1.5rem;
}


/* ---------          selected       --------- */

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $white;
  background-color: $primary-color;
}




/*   old one
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $primary-color !important;
  }
 ---- */


// this too
/*
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: $primary-color !important;
}

 */
