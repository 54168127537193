@import '../../../assets/mixins';

/*
.container {
  display: flex;
}
*/

.profile{
  cursor: pointer;
}

.profile:hover, .profile:focus{
  background-color: #EAF4FE;
}

.profile:focus-within{
  border-color: #3C76B6;
  background-color: #EAF4FE;
  border-width: 1.5px;
}

.previewContainer {
  padding: 10px;
  margin: 10px 10px 10px 10px;
  width: 88%;
}

.defaultCard {
  width: 80%;
  margin: auto;
  overflow: hidden;
}


.subscriptionSection {
  margin-bottom: 10px;
  word-wrap: break-word;
  padding-left: 3%;
}

.formLayout {
  width: 80%;
  min-width: 558px;
  margin-bottom: 1.5rem !important;
}

.investorInvitationWrapper {
  width: 60%;
}

.invitationRadioContainer {
  margin-bottom: 1rem;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-left: 3%;
}

.investorInvitationForm {
  // :global(.ant-row) {
  //   margin-right: 20px;
  //   display: block;
  // }

  input {
    border-radius: .30rem !important;
  }
  :global(.ant-select-selection-item) {
    color: $gray-6;
  }
}

.existingInvestorFormWrapper {
  display: flex;

  :global(.ant-select-selector) {
    width: 17rem !important;
  }
}

.expectedContributionInput {
  width: 13rem;
}


// .titleInput {
//   input {
//     width: 97px !important;
//   }
// }

.taxInput {
  input {
    display: flex;
    justify-content: flex-end;
    width: 13rem !important;
  }
}

.advisorFieldInput {
  :global(.ant-select) {
    width: 143px !important;
  }
}

.otherContactsFieldsWrapper {
  display: flex;
}

.formActionWrapper {
  display: flex;
  justify-content: space-between;
}

.saveButton {
  margin-right: 2rem;
}

.previousButton {
  position: absolute;
  left: 1.5rem;
}

.addButton {
  &:hover {
    color: #428c3f;
  }
}

.plusIcon {
  color: #428c3f;
}

.removeButton {
  &:hover {
    color: #ff0001;
  }
}

.minusIcon {
  color: #ff0001;
}
