@import '../../../assets/mixins';


.topbarNavButtons {
  display: flex;
  // float: ;
  // flex-wrap: nowrap;
  // justify-content: flex-end;
  //  background: $white;
}
// no margins allowed
.navigationBar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  background: transparent; // was white
 // color: $white;
  line-height: 63px;
  width: 100%;
}
.appButtonWrapper {
  button {
    //margin-top: .5rem;
    margin: .5rem;
  }
}
.invite{
  margin-right: 10px;
  margin-top: 14px;
 // background-color: $white;
  // color: $white;
//  background: $pgreen;
}
.dueDiligenceButton{
  margin-right: 10px;
  margin-top: 14px;
  // background: $primary-color;
 // border-color: $primary-color;
 //  color: $white;
}
.ledgerButton{
  margin-right: 10px;
  margin-top: 14px;
// background: $plgray;
}
.newInvestorButton{
  margin-right: 10px;
  margin-top: 14px;
//  color: $pgreen;
//  background: $white;
}
.InvestorAppButton{
  margin-right: 10px;
  margin-top: 14px;
//  background: $pgreen;
}
.fundManagerAppButton{
  margin-right: 10px;
  margin-top: 14px;
//  background: $pgreen;
}


